import Footer from './footer';
import Header from './header';
import React from 'react';

import './about.scss';

function About() {
  return (
    <>
      <Header />
      <div className="About">
        <div className="Information">
          <div className="TitleOne">Moving the needle</div>
          <div className="TitleTwo">on social discourse</div>
          <div className="description">
            Social Impact Media Club is a forum designed to enable social change via media. Initially conceived as a
            meeting place and discussion group for films, SIMC is meant for use in a wide range of media forms
            including, but not limited to, film, television, art, books, and music. The goal of SIMC is to promote
            discussion and action through accessible topics and companies for change.
            <br />
            <br />
            {`SIMC is a place of inclusion and welcoming. No matter your race, ethnicity, religion, gender, or political
          views, you can express your opinions and thoughts freely. In such a forum, it's your responsibility to be
          respectful and promote inclusion. Different people have different views and that's ok. We're all here because
          we want to grow, learn, and contribute to society. Whatever we can do to make the world a better place, even
          small words or actions, will help us all live happier, more sustainable lives.`}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
