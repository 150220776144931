import About from 'components/about';
import Layout from 'components/layout';
import React from 'react';

const index = () => {
  return (
    <Layout>
      <About />
    </Layout>
  );
};

export default index;
